define("ember-yeti-table/-private/utils/filtering-utils", ["exports", "@ember/object", "@ember/utils", "ember-yeti-table/-private/utils/create-regex"], function (_exports, _object, _utils, _createRegex) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = filterData;
  function createColumnFilters(columns) {
    let searcheableColumns = columns.filter(c => {
      return !(0, _utils.isEmpty)((0, _object.get)(c, 'filter')) || !(0, _utils.isEmpty)((0, _object.get)(c, 'filterFunction'));
    });
    return searcheableColumns.map(c => {
      let regex = (0, _createRegex.default)((0, _object.get)(c, 'filter'));
      return row => {
        let value = (0, _object.get)(row, (0, _object.get)(c, 'prop'));
        let passesRegex = true;
        if (!(0, _utils.isEmpty)((0, _object.get)(c, 'filter'))) {
          passesRegex = regex.test(value);
        }
        let passesCustom = true;
        if (!(0, _utils.isEmpty)((0, _object.get)(c, 'filterFunction'))) {
          passesCustom = (0, _object.get)(c, 'filterFunction')(value, (0, _object.get)(c, 'filterUsing'), row);
        }
        return passesRegex && passesCustom;
      };
    });
  }
  function filterData(data, columns, globalFilter, filterFunction, filterUsing) {
    if ((0, _utils.isEmpty)(data)) {
      return [];
    }
    if ((0, _utils.isEmpty)(columns)) {
      // bail out if there are no columns to filter
      return data;
    }
    let globalRegex = (0, _createRegex.default)(globalFilter, false, true, true);
    let columnFilters = createColumnFilters(columns);
    return data.filter(row => {
      let passesGeneral = true;
      if (!(0, _utils.isEmpty)(globalRegex)) {
        passesGeneral = columns.some(c => {
          return globalRegex.test((0, _object.get)(row, (0, _object.get)(c, 'prop')));
        });
      }
      let passesColumn = true;
      if (!(0, _utils.isEmpty)(columnFilters)) {
        passesColumn = columnFilters.every(fn => fn(row));
      }
      let passesCustom = true;
      if (!(0, _utils.isEmpty)(filterFunction)) {
        passesColumn = filterFunction(row, filterUsing);
      }
      return passesGeneral && passesColumn && passesCustom;
    });
  }
});