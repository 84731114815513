define("ember-leaflet/components/polyline-layer", ["exports", "ember-leaflet/components/array-path-layer"], function (_exports, _arrayPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * A class for drawing polyline overlays on a map.
   *
   * @class PolylineLayer
   * @extends ArrayPathLayer
   */
  class PolylineLayer extends _arrayPathLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * How much to simplify the polyline on each zoom level.
       * More means better performance and smoother look, and less
       * means more accurate representation.
       * Defaults to `1.0`.
       *
       * @argument smoothFactor
       * @type {Number}
       */
      'smoothFactor',
      /**
       * Disable polyline clipping.
       * Defaults to `false`.
       *
       * @argument noClip
       * @type {Boolean}
       */
      'noClip']);
    }
    createLayer() {
      return this.L.polyline(...this.requiredOptions, this.options);
    }
  }
  _exports.default = PolylineLayer;
});