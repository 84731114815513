define("ember-yeti-table/components/yeti-table/table/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dehFEbJD",
    "block": "[[[11,\"table\"],[16,0,[30,1,[\"table\"]]],[17,2],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13]],[\"@theme\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/table/template.hbs",
    "isStrictMode": false
  });
});