define("ember-on-helper/helpers/on", ["exports", "@ember/component/helper", "ember-on-helper/utils/event-listener", "@ember/debug"], function (_exports, _helper, _eventListener, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__counts = __counts;
  _exports.default = void 0;
  /* eslint no-param-reassign: "off" */

  /**
   * These are private API and only used for testing instrumentation.
   */
  let adds = 0;
  let removes = 0;
  function __counts() {
    return {
      adds,
      removes
    };
  }
  const assertValidEventOptions = true /* DEBUG */ && (() => {
    const ALLOWED_EVENT_OPTIONS = ['capture', 'once', 'passive'];
    const joinOptions = options => options.map(o => `'${o}'`).join(', ');
    return function (eventOptions, eventName) {
      const invalidOptions = Object.keys(eventOptions).filter(o => !ALLOWED_EVENT_OPTIONS.includes(o));
      (true && !(invalidOptions.length === 0) && (0, _debug.assert)(`ember-on-helper: Provided invalid event options (${joinOptions(invalidOptions)}) to '${eventName}' event listener. Only these options are valid: ${joinOptions(ALLOWED_EVENT_OPTIONS)}`, invalidOptions.length === 0));
    };
  })();
  function setupListener(eventTarget, eventName, callback, eventOptions) {
    if (true /* DEBUG */) assertValidEventOptions(eventOptions, eventName);
    (true && !(eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function') && (0, _debug.assert)(`ember-on-helper: '${eventTarget}' is not a valid event target. It has to be an Element or an object that conforms to the EventTarget interface.`, eventTarget && typeof eventTarget.addEventListener === 'function' && typeof eventTarget.removeEventListener === 'function'));
    (true && !(typeof eventName === 'string' && eventName.length > 1) && (0, _debug.assert)(`ember-on-helper: '${eventName}' is not a valid event name. It has to be a string with a minimum length of 1 character.`, typeof eventName === 'string' && eventName.length > 1));
    (true && !(typeof callback === 'function') && (0, _debug.assert)(`ember-on-helper: '${callback}' is not a valid callback. Provide a function.`, typeof callback === 'function'));
    adds++;
    (0, _eventListener.addEventListener)(eventTarget, eventName, callback, eventOptions);
    return callback;
  }
  function destroyListener(eventTarget, eventName, callback, eventOptions) {
    if (eventTarget && eventName && callback) {
      removes++;
      (0, _eventListener.removeEventListener)(eventTarget, eventName, callback, eventOptions);
    }
  }
  var _default = _exports.default = _helper.default.extend({
    eventTarget: null,
    eventName: undefined,
    callback: undefined,
    eventOptions: undefined,
    compute([eventTarget, eventName, callback], eventOptions) {
      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
      this.eventTarget = eventTarget;
      this.callback = setupListener(this.eventTarget, eventName, callback, eventOptions);
      this.eventName = eventName;
      this.eventOptions = eventOptions;
    },
    willDestroy() {
      this._super();
      destroyListener(this.eventTarget, this.eventName, this.callback, this.eventOptions);
    }
  });
});