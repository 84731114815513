define("ember-yeti-table/components/yeti-table/thead/row/cell/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "A2S2iRBC",
    "block": "[[[41,[30,0,[\"visible\"]],[[[1,\"  \"],[11,\"th\"],[16,0,[29,[[30,1],\" \",[30,2,[\"theadCell\"]]]]],[17,3],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@class\",\"@theme\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/thead/row/cell/template.hbs",
    "isStrictMode": false
  });
});