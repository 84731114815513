define("ember-yeti-table/components/yeti-table/thead/row/column/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "dS++s6Qx",
    "block": "[[[41,[30,0,[\"visible\"]],[[[1,\"  \"],[11,\"th\"],[16,\"role\",[52,[30,0,[\"sortable\"]],\"button\"]],[16,0,[29,[[30,1],\" \",[30,2,[\"theadCell\"]],\" \",[52,[30,0,[\"sortable\"]],[30,2,[\"sorting\",\"columnSortable\"]]],\" \",[52,[30,0,[\"isSorted\"]],[30,2,[\"sorting\",\"columnSorted\"]]],\" \",[52,[30,0,[\"isAscSorted\"]],[30,2,[\"sorting\",\"columnSortedAsc\"]]],\" \",[52,[30,0,[\"isDescSorted\"]],[30,2,[\"sorting\",\"columnSortedDesc\"]]]]]],[17,3],[4,[38,1],[\"click\",[52,[30,0,[\"sortable\"]],[28,[37,2],[[30,4],[30,0]],null],[30,0,[\"noop\"]]]],null],[4,[38,3],[[30,0,[\"updateName\"]]],null],[12],[1,\"\\n    \"],[18,5,[[28,[37,5],null,[[\"isSorted\",\"isAscSorted\",\"isDescSorted\"],[[30,0,[\"isSorted\"]],[30,0,[\"isAscSorted\"]],[30,0,[\"isDescSorted\"]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"@class\",\"@theme\",\"&attrs\",\"@onClick\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"did-insert\",\"yield\",\"hash\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/thead/row/column/template.hbs",
    "isStrictMode": false
  });
});