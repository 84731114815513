define("ember-yeti-table/components/yeti-table/tbody/component", ["exports", "@ember-decorators/component", "@ember/component", "ember-yeti-table/components/yeti-table/tbody/template"], function (_exports, _component, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
    Renders a `<tbody>` element and yields the row component and data. You must iterate each row
    ```hbs
    <table.tbody as |body data|>
      {{#each data as |person index|}}
        <body.row as |row|>
          <row.cell>
            {{person.firstName}} #{{index}}
          </row.cell>
          <row.cell>
            {{person.lastName}}
          </row.cell>
          <row.cell>
            {{person.points}}
          </row.cell>
        </body.row>
      {{/each}}
    </table.tbody>
    ```
    Remember that you must perform the {{#each}} to iterate over the `@data` array.
  
   This component does not provide a blockless variation
  
    @yield {object} body
    @yield {Component} body.row - the row component
    @yield {Array} data
  */
  let TBody = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class TBody extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "data", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "parent", void 0);
      /**
       * Adds a click action to each row, called with the clicked row's data as an argument.
       * Can be used with both the blockless and block invocations.
       */
      _defineProperty(this, "onRowClick", void 0);
    }
  }) || _class) || _class);
  var _default = _exports.default = TBody;
});