define("ember-leaflet/components/geojson-layer", ["exports", "@ember/component", "@ember/object", "ember-leaflet/components/base-layer", "ember-leaflet/components/popup-layer", "ember-leaflet/components/tooltip-layer", "@ember/template-factory"], function (_exports, _component, _object, _baseLayer, _popupLayer, _tooltipLayer, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.leafletDescriptorsProps as |prop|}}
    {{did-update (fn this.updateOption prop) (get this.args prop)}}
  {{/each}}
  
  {{did-update this.didChangeGeojson @geoJSON}}
  
  <@node @didInsertParent={{this.didInsertParent}} @willDestroyParent={{this.willDestroyParent}} as |Node|>
    {{#let (ember-leaflet-hash) as |components|}}
      {{#each this.componentsToYield as |c|}}
        {{ember-leaflet-assign-to
          components
          key=(ember-leaflet-or c.as c.name)
          value=(component (ensure-safe-component (ember-leaflet-or c.component c.name)) parent=this node=Node)
          onChange=this.mergeComponents
        }}
      {{/each}}
  
      {{yield this.mergedComponents}}
    {{/let}}
  </@node>
  */
  {
    "id": "zYuQiAko",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"leafletDescriptorsProps\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],[[28,[37,3],[[30,0,[\"updateOption\"]],[30,1]],null],[28,[37,4],[[30,0,[\"args\"]],[30,1]],null]],null]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[1,[28,[35,2],[[30,0,[\"didChangeGeojson\"]],[30,2]],null]],[1,\"\\n\\n\"],[8,[30,3],null,[[\"@didInsertParent\",\"@willDestroyParent\"],[[30,0,[\"didInsertParent\"]],[30,0,[\"willDestroyParent\"]]]],[[\"default\"],[[[[1,\"\\n\"],[44,[[28,[37,6],null,null]],[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"componentsToYield\"]]],null]],null],null,[[[1,\"      \"],[1,[28,[35,7],[[30,5]],[[\"key\",\"value\",\"onChange\"],[[28,[37,8],[[30,6,[\"as\"]],[30,6,[\"name\"]]],null],[50,[28,[37,10],[[28,[37,8],[[30,6,[\"component\"]],[30,6,[\"name\"]]],null]],null],0,null,[[\"parent\",\"node\"],[[30,0],[30,4]]]],[30,0,[\"mergeComponents\"]]]]]],[1,\"\\n\"]],[6]],null],[1,\"\\n    \"],[18,7,[[30,0,[\"mergedComponents\"]]]],[1,\"\\n\"]],[5]]]],[4]]]]]],[\"prop\",\"@geoJSON\",\"@node\",\"Node\",\"components\",\"c\",\"&default\"],false,[\"each\",\"-track-array\",\"did-update\",\"fn\",\"get\",\"let\",\"ember-leaflet-hash\",\"ember-leaflet-assign-to\",\"ember-leaflet-or\",\"component\",\"ensure-safe-component\",\"yield\"]]",
    "moduleName": "ember-leaflet/components/geojson-layer.hbs",
    "isStrictMode": false
  });

  /**
   * Represents a GeoJSON object or an array of GeoJSON objects. Allows you to render GeoJSON
   * data and display it on the map.
   *
   * @class GeojsonLayer
   * @extends BaseLayer
   */
  let GeojsonLayer = _exports.default = (_class = class GeojsonLayer extends _baseLayer.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * An object in GeoJSON format to display on the map.
       *
       * @argument geoJSON
       * @type {Object}
       */
      'geoJSON']);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * A Function defining how GeoJSON points spawn Leaflet layers. It is internally called when data is added,
       * passing the GeoJSON point feature and its LatLng.
       *
       * @argument pointToLayer
       * @type {Function}
       */
      'pointToLayer',
      /**
       * A Function defining the Path options for styling GeoJSON lines and polygons, called internally when data is added.
       *
       * @argument style
       * @type {Function}
       */
      'style',
      /**
       * A Function that will be called once for each created Feature, after it has been created and styled. Useful
       * for attaching events and popups to features.
       *
       * @argument onEachFeature
       * @type {Function}
       */
      'onEachFeature',
      /**
       * A Function that will be used to decide whether to include a feature or not.
       *
       * @argument filter
       * @type {Function}
       */
      'filter',
      /**
       * A Function that will be used for converting GeoJSON coordinates to LatLngs.
       * The default is the coordsToLatLng static method.
       *
       * @argument coordsToLatLng
       * @type {Function}
       */
      'coordsToLatLng',
      /**
       * Whether default Markers for `Point` type Features inherit from group options.
       * Defaults to `false`.
       *
       * @argument markersInheritOptions
       * @type {Boolean}
       */
      'markersInheritOptions']);
      _defineProperty(this, "leafletEvents", [...this.leafletEvents,
      /**
       * Fired when a layer is added to this FeatureGroup.
       *
       * @argument onLayeradd
       * @type {Function}
       */
      'layeradd',
      /**
       * Fired when a layer is removed from this FeatureGroup.
       *
       * @argument onLayerremove
       * @type {Function}
       */
      'layerremove']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'style']);
      _defineProperty(this, "componentsToYield", [...this.componentsToYield, {
        name: 'popup-layer',
        as: 'popup',
        component: _popupLayer.default
      }, {
        name: 'tooltip-layer',
        as: 'tooltip',
        component: _tooltipLayer.default
      }]);
    }
    didChangeGeojson(geoJSON) {
      if (geoJSON) {
        this.pushDataToLeaflet(geoJSON);
      }
    }
    pushDataToLeaflet(geoJSON) {
      if (!this._layer) {
        return;
      }

      // recall that GeoJSON layers are actually layer groups -- we have to clear
      // their contents first...
      this._layer.clearLayers();

      // we need to update the group layers options before re-adding geojson
      // otherwise, they wouldn't get the changes that could be happening meanwhile
      this._layer.options = this.options;
      if (geoJSON) {
        // ...then add new data to recreate the child layers in an updated form
        this._layer.addData(geoJSON);
      }
    }
    createLayer() {
      return this.L.geoJson(...this.requiredOptions, this.options);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "didChangeGeojson", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didChangeGeojson"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GeojsonLayer);
});