define("ember-yeti-table/components/yeti-table/tfoot/row/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "e6P1H+TP",
    "block": "[[[11,\"tr\"],[16,0,[29,[[30,1],\" \",[30,2,[\"tfootRow\"]],\" \",[30,2,[\"row\"]]]]],[17,3],[12],[1,\"\\n  \"],[18,4,[[28,[37,1],null,[[\"cell\"],[[50,\"ember-yeti-table@yeti-table/tfoot/row/cell/component\",0,null,[[\"theme\",\"parent\"],[[30,2],[30,0]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@class\",\"@theme\",\"&attrs\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/tfoot/row/template.hbs",
    "isStrictMode": false
  });
});