define("ember-yeti-table/components/yeti-table/thead/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "c/p4XRq3",
    "block": "[[[11,\"thead\"],[16,0,[30,1,[\"thead\"]]],[17,2],[12],[1,\"\\n  \"],[18,8,[[28,[37,1],null,[[\"row\"],[[50,\"ember-yeti-table@yeti-table/thead/row/component\",0,null,[[\"sortable\",\"sortSequence\",\"onColumnClick\",\"columns\",\"theme\",\"parent\"],[[30,3],[30,4],[30,5],[30,6],[30,1],[30,7]]]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@theme\",\"&attrs\",\"@sortable\",\"@sortSequence\",\"@onColumnClick\",\"@columns\",\"@parent\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/thead/template.hbs",
    "isStrictMode": false
  });
});