define("ember-leaflet/initializers/leaflet-assets", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  /* global L */

  function initialize(container /*, application */) {
    if (typeof FastBoot === 'undefined' && typeof L !== 'undefined') {
      let config = container.resolveRegistration('config:environment');
      let prefix = '';
      if (!(0, _utils.isNone)(config.rootURL)) {
        prefix = config.rootURL;
      } else if (!(0, _utils.isNone)(config.baseURL)) {
        prefix = config.baseURL;
      }
      L.Icon.Default.imagePath = `${prefix}assets/images/`;
    }
  }
  var _default = _exports.default = {
    name: 'leaflet-assets',
    initialize
  };
});