define("ember-yeti-table/components/yeti-table/tbody/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "OabZQuit",
    "block": "[[[11,\"tbody\"],[16,0,[30,1,[\"tbody\"]]],[17,2],[12],[1,\"\\n  \"],[18,5,[[28,[37,1],null,[[\"row\"],[[50,\"ember-yeti-table@yeti-table/tbody/row/component\",0,null,[[\"theme\",\"onClick\",\"columns\"],[[30,1],[30,0,[\"onRowClick\"]],[30,3]]]]]]],[30,4]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@theme\",\"&attrs\",\"@columns\",\"@data\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/tbody/template.hbs",
    "isStrictMode": false
  });
});