define("ember-notify/index", ["exports", "@ember/object", "@ember/template", "@ember/service", "ember-notify/message"], function (_exports, _object, _template, _service, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function aliasToShow(type) {
    return function (message, options) {
      return this.show(type, message, options);
    };
  }
  let Notify = _service.default.extend({
    info: aliasToShow('info'),
    success: aliasToShow('success'),
    warning: aliasToShow('warning'),
    alert: aliasToShow('alert'),
    error: aliasToShow('error'),
    init() {
      this._super(...arguments);
      this.pending = [];
    },
    show(type, text, options) {
      // If the text passed is `SafeString`, convert it
      if ((0, _template.isHTMLSafe)(text)) {
        text = text.toString();
      }
      if (typeof text === 'object') {
        options = text;
        text = null;
      }
      let message = _message.default.create(Object.assign({
        text: text,
        type: type
      }, options));
      if (this.target) {
        this.target.show(message);
      } else {
        this.pending.push(message);
      }
      return message;
    },
    setTarget(target) {
      this.set('target', target);
      if (target) {
        this.pending.map(message => target.show(message));
        this.pending = [];
      }
    }
  });
  var _default = _exports.default = Notify.reopenClass({
    property() {
      return (0, _object.computed)(function () {
        return Notify.create();
      });
    }
  });
});