define("ember-yeti-table/components/yeti-table/header/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "kCB9BUmG",
    "block": "[[[11,\"thead\"],[16,0,[30,1,[\"thead\"]]],[17,2],[12],[1,\"\\n  \"],[10,\"tr\"],[15,0,[29,[[30,3],\" \",[30,1,[\"theadRow\"]],\" \",[30,1,[\"row\"]]]]],[12],[1,\"\\n    \"],[18,7,[[28,[37,1],null,[[\"column\"],[[50,\"ember-yeti-table@yeti-table/thead/row/column/component\",0,null,[[\"sortable\",\"sortSequence\",\"onClick\",\"parent\",\"theme\"],[[30,4],[30,5],[28,[37,3],[[30,0,[\"onColumnClickHeader\"]]],null],[30,6],[30,1]]]]]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@theme\",\"&attrs\",\"@trClass\",\"@sortable\",\"@sortSequence\",\"@parent\",\"&default\"],false,[\"yield\",\"hash\",\"component\",\"fn\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/header/template.hbs",
    "isStrictMode": false
  });
});