define("ember-yeti-table/components/yeti-table/header/component", ["exports", "@ember-decorators/component", "@ember/component", "@ember/object", "ember-yeti-table/components/yeti-table/header/template"], function (_exports, _component, _component2, _object, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  /**
    Renders a `<thead>` element and yields the column component.
    ```hbs
    <table.header as |header|>
      <header.column @prop="firstName">
        First name
      </header.column>
      <header.column @prop="lastName">
        Last name
      </header.column>
      <header.column @prop="points">
        Points
      </header.column>
    </table.header>
    ```
    @yield {object} header
    @yield {Component} header.column       the column component
  */
  let Header = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = (_class2 = class Header extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "sortable", void 0);
      _defineProperty(this, "sortSequence", void 0);
      _defineProperty(this, "onColumnClick", void 0);
      _defineProperty(this, "parent", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "trClass", void 0);
    }
    onColumnClickHeader(column, e) {
      if (this.get('onColumnClick') && column.get('sortable')) {
        this.get('onColumnClick')(column, e);
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "onColumnClickHeader", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onColumnClickHeader"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = Header;
});