define("@ember-data/legacy-compat/builders", ["exports", "@ember/debug", "@ember-data/request", "@ember/string", "@ember-data/store/-private", "@ember-data/store"], function (_exports, _debug, _request, _string, _private, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.findAll = findAllBuilder;
  _exports.findRecord = findRecordBuilder;
  _exports.query = queryBuilder;
  _exports.queryRecord = queryRecordBuilder;
  _exports.saveRecord = saveRecordBuilder;
  function isMaybeIdentifier(maybeIdentifier) {
    return Boolean(maybeIdentifier !== null && typeof maybeIdentifier === 'object' && ('id' in maybeIdentifier && 'type' in maybeIdentifier && maybeIdentifier.id && maybeIdentifier.type || maybeIdentifier.lid));
  }
  function normalizeModelName(type) {
    return (0, _string.dasherize)(type);
  }

  /**
   * @module @ember-data/legacy-compat/builders
   */

  // Keeping unused generics for consistency with 5x types

  /**
    This function builds a request config to perform a `findAll` request for the given type.
    When passed to `store.request`, this config will result in the same behavior as a `store.findAll` request.
    Additionally, it takes the same options as `store.findAll`.
  
    All `@ember-data/legacy-compat` builders exist to enable you to migrate your codebase to using the correct syntax for `store.request` while temporarily preserving legacy behaviors.
    This is useful for quickly upgrading an entire app to a unified syntax while a longer incremental migration is made to shift off of adapters and serializers.
    To that end, these builders are deprecated and will be removed in a future version of Ember Data.
  
    @method findAll
    @deprecated
    @public
    @static
    @for @ember-data/legacy-compat/builders
    @param {string} type the name of the resource
    @param {object} query a query to be used by the adapter
    @param {FindAllBuilderOptions} [options] optional, may include `adapterOptions` hash which will be passed to adapter.findAll
    @return {FindAllRequestInput} request config
  */

  function findAllBuilder(type, options = {}) {
    (true && !(type) && (0, _debug.assert)(`You need to pass a model name to the findAll builder`, type));
    (true && !(typeof type === 'string') && (0, _debug.assert)(`Model name passed to the findAll builder must be a dasherized string instead of ${type}`, typeof type === 'string'));
    return {
      op: 'findAll',
      data: {
        type: normalizeModelName(type),
        options: options || {}
      },
      cacheOptions: {
        [_request.SkipCache]: true
      }
    };
  }

  /**
   * @module @ember-data/legacy-compat/builders
   */

  /**
    This function builds a request config to find the record for a given identifier or type and id combination.
    When passed to `store.request`, this config will result in the same behavior as a `store.findRecord` request.
    Additionally, it takes the same options as `store.findRecord`, with the exception of `preload` (which is unsupported).
  
    **Example 1**
  
    ```ts
    import { findRecord } from '@ember-data/legacy-compat/builders';
    const { content: post } = await store.request<Post>(findRecord<Post>('post', '1'));
    ```
  
    **Example 2**
  
    `findRecord` can be called with a single identifier argument instead of the combination
    of `type` (modelName) and `id` as separate arguments. You may recognize this combo as
    the typical pairing from [JSON:API](https://jsonapi.org/format/#document-resource-object-identification)
  
    ```ts
    import { findRecord } from '@ember-data/legacy-compat/builders';
    const { content: post } = await store.request<Post>(findRecord<Post>({ type: 'post', id }));
    ```
  
    All `@ember-data/legacy-compat` builders exist to enable you to migrate your codebase to using the correct syntax for `store.request` while temporarily preserving legacy behaviors.
    This is useful for quickly upgrading an entire app to a unified syntax while a longer incremental migration is made to shift off of adapters and serializers.
    To that end, these builders are deprecated and will be removed in a future version of Ember Data.
  
    @method findRecord
    @deprecated
    @public
    @static
    @for @ember-data/legacy-compat/builders
    @param {string|object} type - either a string representing the name of the resource or a ResourceIdentifier object containing both the type (a string) and the id (a string) for the record or an lid (a string) of an existing record
    @param {string|number|object} id - optional object with options for the request only if the first param is a ResourceIdentifier, else the string id of the record to be retrieved
    @param {FindRecordBuilderOptions} [options] - if the first param is a string this will be the optional options for the request. See examples for available options.
    @return {FindRecordRequestInput} request config
  */

  function findRecordBuilder(resource, idOrOptions, options) {
    (true && !(resource) && (0, _debug.assert)(`You need to pass a modelName or resource identifier as the first argument to the findRecord builder`, resource));
    if (isMaybeIdentifier(resource)) {
      options = idOrOptions;
    } else {
      (true && !(typeof resource === 'string') && (0, _debug.assert)(`You need to pass a modelName or resource identifier as the first argument to the findRecord builder (passed ${resource})`, typeof resource === 'string'));
      const type = normalizeModelName(resource);
      const normalizedId = (0, _private.ensureStringId)(idOrOptions);
      resource = (0, _private.constructResource)(type, normalizedId);
    }
    options = options || {};
    (true && !(!options.preload) && (0, _debug.assert)('findRecord builder does not support options.preload', !options.preload));
    return {
      op: 'findRecord',
      data: {
        record: resource,
        options
      },
      cacheOptions: {
        [_request.SkipCache]: true
      }
    };
  }

  /**
   * @module @ember-data/legacy-compat/builders
   */

  /**
    This function builds a request config for a given type and query object.
    When passed to `store.request`, this config will result in the same behavior as a `store.query` request.
    Additionally, it takes the same options as `store.query`.
  
    All `@ember-data/legacy-compat` builders exist to enable you to migrate your codebase to using the correct syntax for `store.request` while temporarily preserving legacy behaviors.
    This is useful for quickly upgrading an entire app to a unified syntax while a longer incremental migration is made to shift off of adapters and serializers.
    To that end, these builders are deprecated and will be removed in a future version of Ember Data.
  
    @method query
    @deprecated
    @public
    @static
    @for @ember-data/legacy-compat/builders
    @param {string} type the name of the resource
    @param {object} query a query to be used by the adapter
    @param {QueryBuilderOptions} [options] optional, may include `adapterOptions` hash which will be passed to adapter.query
    @return {QueryRequestInput} request config
  */

  function queryBuilder(type, query, options = {}) {
    (true && !(type) && (0, _debug.assert)(`You need to pass a model name to the query builder`, type));
    (true && !(query) && (0, _debug.assert)(`You need to pass a query hash to the query builder`, query));
    (true && !(typeof type === 'string') && (0, _debug.assert)(`Model name passed to the query builder must be a dasherized string instead of ${type}`, typeof type === 'string'));
    return {
      op: 'query',
      data: {
        type: normalizeModelName(type),
        query,
        options: options
      },
      cacheOptions: {
        [_request.SkipCache]: true
      }
    };
  }

  /**
    This function builds a request config for a given type and query object.
    When passed to `store.request`, this config will result in the same behavior as a `store.queryRecord` request.
    Additionally, it takes the same options as `store.queryRecord`.
  
    All `@ember-data/legacy-compat` builders exist to enable you to migrate your codebase to using the correct syntax for `store.request` while temporarily preserving legacy behaviors.
    This is useful for quickly upgrading an entire app to a unified syntax while a longer incremental migration is made to shift off of adapters and serializers.
    To that end, these builders are deprecated and will be removed in a future version of Ember Data.
  
    @method queryRecord
    @deprecated
    @public
    @static
    @for @ember-data/legacy-compat/builders
    @param {string} type the name of the resource
    @param {object} query a query to be used by the adapter
    @param {QueryBuilderOptions} [options] optional, may include `adapterOptions` hash which will be passed to adapter.query
    @return {QueryRecordRequestInput} request config
  */

  function queryRecordBuilder(type, query, options) {
    (true && !(type) && (0, _debug.assert)(`You need to pass a model name to the queryRecord builder`, type));
    (true && !(query) && (0, _debug.assert)(`You need to pass a query hash to the queryRecord builder`, query));
    (true && !(typeof type === 'string') && (0, _debug.assert)(`Model name passed to the queryRecord builder must be a dasherized string instead of ${type}`, typeof type === 'string'));
    return {
      op: 'queryRecord',
      data: {
        type: normalizeModelName(type),
        query,
        options: options || {}
      },
      cacheOptions: {
        [_request.SkipCache]: true
      }
    };
  }

  /**
   * @module @ember-data/legacy-compat/builders
   */
  function _resourceIsFullDeleted(identifier, cache) {
    return cache.isDeletionCommitted(identifier) || cache.isNew(identifier) && cache.isDeleted(identifier);
  }
  function resourceIsFullyDeleted(instanceCache, identifier) {
    const cache = instanceCache.cache;
    return !cache || _resourceIsFullDeleted(identifier, cache);
  }

  /**
    This function builds a request config for saving the given record (e.g. creating, updating, or deleting the record).
    When passed to `store.request`, this config will result in the same behavior as a legacy `store.saveRecord` request.
    Additionally, it takes the same options as `store.saveRecord`.
  
    All `@ember-data/legacy-compat` builders exist to enable you to migrate your codebase to using the correct syntax for `store.request` while temporarily preserving legacy behaviors.
    This is useful for quickly upgrading an entire app to a unified syntax while a longer incremental migration is made to shift off of adapters and serializers.
    To that end, these builders are deprecated and will be removed in a future version of Ember Data.
  
    @method saveRecord
    @deprecated
    @public
    @static
    @for @ember-data/legacy-compat/builders
    @param {object} record a record to save
    @param {SaveRecordBuilderOptions} options optional, may include `adapterOptions` hash which will be passed to adapter.saveRecord
    @return {SaveRecordRequestInput} request config
  */
  function saveRecordBuilder(record, options = {}) {
    const store = (0, _store.storeFor)(record);
    (true && !(store) && (0, _debug.assert)(`Unable to initiate save for a record in a disconnected state`, store));
    const identifier = (0, _store.recordIdentifierFor)(record);
    if (!identifier) {
      // this commonly means we're disconnected
      // but just in case we throw here to prevent bad things.
      throw new Error(`Record Is Disconnected`);
    }
    (true && !(store._instanceCache.recordIsLoaded(identifier)) && (0, _debug.assert)(`Cannot initiate a save request for an unloaded record: ${identifier.lid}`, store._instanceCache.recordIsLoaded(identifier)));
    if (resourceIsFullyDeleted(store._instanceCache, identifier)) {
      throw new Error('cannot build saveRecord request for deleted record');
    }
    if (!options) {
      options = {};
    }
    let operation = 'updateRecord';
    const cache = store.cache;
    if (cache.isNew(identifier)) {
      operation = 'createRecord';
    } else if (cache.isDeleted(identifier)) {
      operation = 'deleteRecord';
    }
    return {
      op: operation,
      data: {
        options,
        record: identifier
      },
      records: [identifier],
      cacheOptions: {
        [_request.SkipCache]: true
      }
    };
  }
});