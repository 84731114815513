define("ember-notify/templates/components/ember-notify/message", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "gZURRqGJ",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,[[30,0,[\"message\"]],[28,[37,3],[[30,0],\"close\"],null]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[11,3],[24,6,\"\"],[24,0,\"close\"],[24,\"role\",\"button\"],[4,[38,3],[[30,0],\"close\"],null],[12],[1,\"×\"],[13],[1,\"\\n\"],[1,\"  \"],[10,1],[14,0,\"message\"],[12],[1,[30,0,[\"message\",\"text\"]]],[2,[30,0,[\"message\",\"html\"]]],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"action\"]]",
    "moduleName": "ember-notify/templates/components/ember-notify/message.hbs",
    "isStrictMode": false
  });
});