define("ember-render-helpers/helpers/did-update", ["exports", "@ember/debug", "ember-render-helpers/helpers/did-update-helper"], function (_exports, _debug, _didUpdateHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DeprecatedDidUpdateHelper extends _didUpdateHelper.default {
    /* eslint-disable-next-line @typescript-eslint/ban-types */
    constructor(properties) {
      super(properties);
      (true && !(false) && (0, _debug.deprecate)('The {{did-update}} helper has been renamed to {{did-update-helper}}.', false, {
        id: 'new-helper-names',
        until: '1.0.0',
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore: Outdated types do not know the for property yet but cannot be upgraded.
        for: 'ember-render-helpers',
        since: {
          available: '0.2.1',
          enabled: '0.2.1'
        }
      }));
    }
  }
  _exports.default = DeprecatedDidUpdateHelper;
});