define("ember-yeti-table/components/yeti-table/table/component", ["exports", "@ember-decorators/component", "@ember/component", "ember-yeti-table/components/yeti-table/table/template"], function (_exports, _component, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  /**
    A simple component that just renders the `<table>` element with the correct
    theme classes.
  
    This component should only be needed when using `@renderTableElement={{false}}` to render
    the table element yourself. Please refer to the `@renderTableElement` documentation for
    more information.
  
    Example:
  
    ```hbs
    <YetiTable @data={{this.data}} @pagination={{true}} @renderTableElement={{false}} as |t|>
  
      <t.table> {{!-- we render the given table component ourselves --}}
        <t.header as |header|>
          ...
        </t.header>
  
        <t.body/>
      </t.table>
  
      <t.pagination/> {{!-- pagination controls outside the <table> element --}}
  
    </YetiTable>
    ```
  */
  let Table = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class Table extends _component2.default {}) || _class) || _class);
  var _default = _exports.default = Table;
});