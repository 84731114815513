define("ember-yeti-table/components/yeti-table/body/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QWVissIX",
    "block": "[[[11,\"tbody\"],[16,0,[30,1,[\"tbody\"]]],[17,2],[12],[1,\"\\n\"],[41,[48,[30,10]],[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"      \"],[18,10,[[28,[37,5],null,[[\"row\"],[[50,\"ember-yeti-table@yeti-table/tbody/row/component\",0,null,[[\"theme\",\"onClick\",\"columns\"],[[30,1],[30,0,[\"onRowClick\"]],[30,6]]]]]]],[30,4],[30,5]]],[1,\"\\n\"]],[4,5]],null],[1,\"\\n\"]],[]],[[[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,3]],null]],null],null,[[[1,\"      \"],[8,[39,7],null,[[\"@theme\",\"@onClick\",\"@columns\"],[[30,1],[52,[30,0,[\"onRowClick\"]],[28,[37,8],[[30,0,[\"handleRowClick\"]],[30,7]],null]],[30,6]]],[[\"default\"],[[[[1,\"\\n\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,6]],null]],null],null,[[[1,\"            \"],[8,[30,8,[\"cell\"]],null,[[\"@class\"],[[30,9,[\"columnClass\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,9,[\"prop\"]],[[[1,\"                \"],[1,[28,[35,9],[[30,7],[30,9,[\"prop\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[30,7]],[1,\"\\n\"]],[]]],[1,\"            \"]],[]]]]],[1,\"\\n\"]],[9]],null],[1,\"\\n      \"]],[8]]]]],[1,\"\\n\"]],[7]],null]],[]]],[13]],[\"@theme\",\"&attrs\",\"@data\",\"rowData\",\"index\",\"@columns\",\"rowData\",\"row\",\"column\",\"&default\"],false,[\"if\",\"has-block\",\"each\",\"-track-array\",\"yield\",\"hash\",\"component\",\"ember-yeti-table@yeti-table/tbody/row/component\",\"fn\",\"get\"]]",
    "moduleName": "ember-yeti-table/components/yeti-table/body/template.hbs",
    "isStrictMode": false
  });
});