define("ember-yeti-table/components/yeti-table/thead/row/component", ["exports", "@ember-decorators/component", "@ember/array", "@ember/component", "ember-yeti-table/components/yeti-table/thead/row/template"], function (_exports, _component, _array, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
    Renders a `<tr>` element and yields the column and cell component.
    ```hbs
    <table.thead as |head|>
      <head.row as |row|>
        <row.column @prop="firstName" as |column|>
          First name
          {{if column.isAscSorted "(sorted asc)"}}
          {{if column.isDescSorted "(sorted desc)"}}
        </head.column>
      </head.row>
    </table.thead>
    ```
  
    @yield {Component} column
    @yield {Component} cell
  */
  let THeadRow = (_dec = (0, _component.tagName)(''), _dec2 = (0, _component.layout)(_template.default), _dec(_class = _dec2(_class = class THeadRow extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "theme", void 0);
      _defineProperty(this, "parent", void 0);
      _defineProperty(this, "columns", void 0);
      _defineProperty(this, "sortable", true);
      _defineProperty(this, "sort", null);
      _defineProperty(this, "sortSequence", void 0);
      _defineProperty(this, "onColumnClick", void 0);
      _defineProperty(this, "cells", (0, _array.A)());
    }
    registerCell(cell) {
      let columns = this.get('columns');
      let prop = cell.get('prop');
      if (prop) {
        let column = columns.findBy('prop', prop);
        cell.set('column', column);
      } else {
        let index = this.get('cells.length');
        let column = columns[index];
        cell.set('column', column);
      }
      this.get('cells').addObject(cell);
    }
    unregisterCell(cell) {
      this.get('cells').removeObject(cell);
    }
  }) || _class) || _class);
  var _default = _exports.default = THeadRow;
});